<template>
  <a-card :body-style="{ padding: '24px 0px' }" :bordered="false">
    <teacher-profile />
  </a-card>
</template>

<script>
import TeacherProfile from './components/Profile'

export default {
  name: 'AddClient',
  components: {
    TeacherProfile
  },
  data() {
    return {
      description: '用户创建编辑页面'
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
