<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="10" :md="24" :sm="24">
          <a-form-item label="昵称" :labelCol="{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-input v-decorator="[
              'nick_name',
              {
                initialValue: info.nick_name
              },
            ]" placeholder="昵称" />
          </a-form-item>
        </a-col>
        <a-col :lg="14" :md="24" :sm="24">
          <a-form-item label="性别" :labelCol="{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-select v-decorator="[
              'sex',
              {
                rules: [{ required: true, message: '请选择性别' }],
                initialValue: info.sex
              }
            ]" placeholder="选择性别">
              <a-select-option v-for="option in sex_type" :key="option" :value="option">{{ option }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="10" :md="24" :sm="24">
          <a-form-item label="用户名" :labelCol="{ lg: { span: 17 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-input v-decorator="[
              'username',
              {
                rules: [{ required: true, message: '请输入用户名' }],
                initialValue: info.username
              }
            ]" placeholder="登录用户名" />
          </a-form-item>
        </a-col>
        <a-col :lg="14" :md="24" :sm="24">
          <a-form-item label="邮箱" :labelCol="{ lg: { span: 5 }, md: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 7 }, md: { span: 17 }, sm: { span: 17 } }">
            <a-input v-decorator="[
              'email',
              {
                rules: [
                  { type: 'email', message: '邮箱格式错误' },
                ],
                initialValue: info.email
              }
            ]" type="email" placeholder="邮箱" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-password v-decorator="[
          'password',
          {
            rules: [
              {
                required: checkPass, message: '请输入密码'
              },
              {
                validator: handleCheckPwd
              }
            ]
          }
        ]" placeholder="密码" />
      </a-form-item>
      <a-form-item label="确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-password v-decorator="[
          'pwd_confirm',
          {
            rules: [
              {
                required: checkPass, message: '请再次输入密码'
              },
              {
                validator: handleCfmPwd
              }
            ]
          }
        ]" placeholder="注：修改用户时，如果填了密码和确认密码，系统则认为是重置密码行为" />
      </a-form-item>
      <a-form-item label="电话号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'phone',
          {
            rules: [{ required: true, message: '请输入电话号码' }],
            initialValue: info.phone
          },
        ]" style="width: 100%">
          <a-select slot="addonBefore" v-decorator="['pre_code', { initialValue: info.pre_code }]" style="width: 70px">
            <a-select-option v-for="option in phone_region" :key="option" :value="option">{{ option }}</a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item label="微信号" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'wx',
          {
            initialValue: info.wx
          }
        ]" placeholder="微信号" />
      </a-form-item>
      <a-form-item label="短信" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'sms_notice_status',
          {
            valuePropName: 'checked',
            initialValue: info.sms_notice_status,
          },
        ]">
          开启短信通知
        </a-checkbox>
      </a-form-item>
      <a-form-item label="邮件" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'email_notice_status',
          {
            valuePropName: 'checked',
            initialValue: info.email_notice_status,
          },
        ]">
          开启邮件通知
        </a-checkbox>
      </a-form-item>
      <a-form-item label="微信" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'wx_notice_status',
          {
            valuePropName: 'checked',
            initialValue: info.wx_notice_status,
          },
        ]">
          开启微信通知
        </a-checkbox>
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'status',
          {
            rules: [{ required: true, message: '请选择用户状态' }],
            initialValue: info.status
          }
        ]" placeholder="请选择用户状态">
          <a-select-option v-for="option in teacher_status_type" :key="option" :value="option">{{ option }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="显示状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'display_status',
          {
            rules: [{ required: true, message: '请选择显示状态' }],
            initialValue: info.display_status
          }
        ]" placeholder="请选择用户状态">
          <a-select-option v-for="option in teacher_display_status" :key="option" :value="option">{{ option }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="4" placeholder="管理员备注信息" v-decorator="[
          'notes',
          {
            initialValue: info.notes
          }
        ]" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { teacherCreate, teacherAdditional, teacherSave } from '@/api/teacher'
import { Cascader as ACascader } from 'ant-design-vue'

export default {
  name: 'TeacherProfile',
  props: {
    id: {
      type: Number,
      default: undefined
    },
    info: {
      type: Object,
      default: () => {
        return {
          pre_code: '+86',
          sex: '未知',
          status: 'Active'
        }
      }
    }
  },
  components: {
    ACascader
  },
  data() {
    return {
      description: '教员创建编辑页面',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      form: this.$form.createForm(this),
      checkPass: true,
      divisionDefault: [],
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)
      // additional
      phone_region: {},
      sex_type: {},
      teacher_status_type: {},
      teacher_display_status: {},
      // form
      submitLoading: false
    }
  },
  created() {
    this.loadingAdditional()
    if (this.id !== undefined) {
      this.checkPass = false
      this.pageType = 'edit'
      // this.loadInfo(this.clientId)
    }
    if (this.info) {
      const data = this.info
      if (data.sms_notice_status === 1) {
        data.sms_notice_status = true
      } else {
        data.sms_notice_status = false
      }
      if (data.wx_notice_status === 1) {
        data.wx_notice_status = true
      } else {
        data.wx_notice_status = false
      }
      if (data.email_notice_status === 1) {
        data.email_notice_status = true
      } else {
        data.email_notice_status = false
      }
      this.info = data
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    loadingAdditional() {
      new Promise((resolve, reject) => {
        teacherAdditional().then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.phone_region = data.phone_region
        this.sex_type = data.sex_type
        this.teacher_status_type = data.teacher_status_type
        this.teacher_display_status = data.teacher_display_status
      })
    },
    handleCheckPwd(rules, value, callback) {
      var cfmPwd = this.form.getFieldValue('pwd_confirm')
      if (cfmPwd && cfmPwd !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    handleCfmPwd(rules, value, callback) {
      var password = this.form.getFieldValue('password')
      if (password && password !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.id
              teacherSave(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              this.form.setFieldsValue({
                password: '',
                pwd_confirm: ''
              })
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              teacherCreate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              this.form.setFieldsValue({
                password: '',
                pwd_confirm: ''
              })
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
