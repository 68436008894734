import api from './index'
import { axios } from '@/utils/request'

// 获取教员列表
export function teacherList(parameter) {
    return axios({
        url: api.TeacherList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取教员详情
export function teacherInfo(parameter) {
    return axios({
        url: api.TeacherInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 搜索获取教员信息
export function teacherSearch(parameter) {
    return axios({
        url: api.TeacherSearch,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取添加用户页面附加数据
export function teacherAdditional() {
    return axios({
        url: api.TeacherAdditional,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加教员
export function teacherCreate(parameter) {
    return axios({
        url: api.TeacherCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 编辑教员
export function teacherSave(parameter) {
    return axios({
        url: api.TeacherSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除教员
export function teacherDelete(parameter) {
    return axios({
        url: api.TeacherDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取教员审核列表
export function teacherInfoList(parameter) {
    return axios({
        url: api.TeacherInfoList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取教员审核单详情
export function teacherInfoDetail(parameter) {
    return axios({
        url: api.TeacherInfoDetail,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 审核教员申请通过
export function teacherInfoApproved(parameter) {
    return axios({
        url: api.TeacherInfoApproved,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 审核教员申请不通过
export function teacherInfoAuditFailed(parameter) {
    return axios({
        url: api.TeacherInfoAuditFailed,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
